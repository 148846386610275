import React from "react";
import TopBanner from "../../../../components/hasuracon2021/homepage/TopBanner";
import InterestedSpeakers from "../../../../components/hasuracon2021/homepage/InterestedSpeakers";
import Speakers from "../../../../components/hasuracon2021/homepage/Speakers";
import TicketComponents from "../../../../components/hasuracon2021/homepage/TicketComponents";
import Talks from "../../../../components/hasuracon2021/homepage/Talks";
import Workshops from "../../../../components/hasuracon2021/homepage/Workshops";
import Sponsor from "../../../../components/hasuracon2021/homepage/Sponsor";
import WelcomeHasuraCon from "../../../../components/hasuracon2021/homepage/WelcomeHasuraCon";
import Seo from "../../../../components/seo";
import Layout from "../../../../components/hasuracon2021/Layout";
import heroBg from "../../../../components/hasuracon2021/images/hero-bg.png";
import interestedSpeakerGraphic from "../../../../components/hasuracon2021/images/interested-speaker-graphic.png";
import { websiteApiUrl } from "../../../../endpoints";

const canonicalUrl = "https://hasura.io/events/hasura-con-2021/";

const HasuraCon = ({ location, pageContext }) => {
  const { presentations, presenters } = pageContext;
  const search = location.search;
  const code = search ? search.split("?")[1] : null;
  React.useEffect(() => {
    if (code === null) {
      if (typeof window !== undefined) {
        window.location.href = "/events/hasura-con-2021/register/";
      }
    }
    return () => {};
  }, [code]);
  const ogImage = {
    ogImage: `${websiteApiUrl}/hasuracon-2021/ogimage/${code}`,
  };
  return (
    <Layout
      location={location}
      sideBarGraphic={() => (
        <img className="leftInterested" src={interestedSpeakerGraphic} alt="Graphic" />
      )}
      heroGraphic={() => (
        <img
          className="heroBanRightPattern homePattern ticketCheckout"
          loading="lazy"
          src={heroBg}
          alt="Speakers Graphic"
        />
      )}
    >
      <Seo
        title="HasuraCon’21: An online Hasura GraphQL user conference"
        description="HasuraCon is a free, online conference happening on 23rd & 24th June. Featuring talks and live, hand-on workshops!"
        meta={ogImage}
        canonicalLink={canonicalUrl}
      />
      <TicketComponents location={location} />
      <TopBanner
        location={location}
        title="The Big Hasura User Conference"
        ctaText="View Recordings →"
        ctaAction="/events/hasura-con-2021/recordings/"
        isTicketPage
      >
        We’re bringing together users from all over the world for a 2 day celebration of all things
        Hasura! It is a conference about you, and your success. Discover the amazing products
        developers are building, level up your Hasura skills with live, in-depth workshops and hear
        about all the big features and updates we are making to the Hasura GraphQL Engine and Hasura
        Cloud. Enjoy a deep-dive into the tech that makes Hasura...well...Hasura.
      </TopBanner>
      <InterestedSpeakers />
      <Speakers presenters={presenters} featured={true} />
      <Talks presentations={presentations} featured={true} />
      <Workshops presentations={presentations} featured={true} />
      <Sponsor />
      <WelcomeHasuraCon />
    </Layout>
  );
};

export default HasuraCon;
