import React, { useState } from "react";
import { Link } from "gatsby";

import Ticket from "../thankyou/Ticket";
import "../styles.scss";

const TicketComponents = ({ location }) => {
  const [user, setUser] = useState(null);
  return (
    <section className="sectionWrapper removePaddTop borderBottom">
      <div className="hasuraConTicketComponentsWrapper">
        <div>
          {user ? (
            <div className="articleTitle">
              Join {user}
              <br />
              at HasuraCon!
            </div>
          ) : null}
          <div className="articleDesc">Get your ticket below.</div>
          <div className="buttonWrapper">
            <Link to="/events/hasura-con-2021/register/">
              <button className="hasuraConCommonAction hasuraConBlueBtn">Register Now →</button>
            </Link>
          </div>
        </div>
        <Ticket location={location} setUser={setUser} />
      </div>
    </section>
  );
};

export default TicketComponents;
