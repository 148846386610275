import React, { useEffect, useState } from "react";
import "./ticket.scss";
import hasuraConBrand from "../images/hasura-con-brand-light.png";
import axios from "axios";
import { websiteApiUrl } from "../../../endpoints";
import { techState } from "./TechState";

const Ticket = ({ ticketInput, location, setUser, currentImg, currentTechImg, currentTech }) => {
  useEffect(() => {
    // logic to see if ticketInput exists or query param exists and make an API call to get the hashed ticket code
    const search = location ? location.search : null;
    const code = search ? search.split("?")[1].split("&")[0].split("=")[1] : null;
    let reqObj = {};
    if (code === null) {
      // logic for ticketInput
      reqObj = ticketInput;
    } else {
      // logic for code query param
      // const tech = search ? search.split("&")[1].split("=")[1] : null;
      const techParam = search ? search.split("?")[1].split("&")[1] : null;
      const tech = techParam ? techParam.split("=")[1] : null;
      const splitCode = code.split("-");
      const firstName = splitCode[0];
      const lastName = splitCode[1];
      const id = splitCode[2];
      reqObj = { id, firstName, lastName };
      const filteredTech = techState.filter(t => t.title === tech);
      if (filteredTech && filteredTech[0]) {
        setTechFromParams(filteredTech[0]);
      }
    }
    // make the api call now
    axios({
      url: `${websiteApiUrl}/hasuracon-2021/get-hashed-ticket`,
      method: "post",
      data: reqObj,
      responseType: "json",
    })
      .then(response => {
        if (response.status === 200) {
          setData(response.data);
          setTicketLoaded(true);
          setUser(response.data.firstName);
        } else {
          setErrorMessageState("Ticket doesn't exist");
        }
      })
      .catch(err => {
        console.error("err", err.response);
        if (err.response && err.response.data) {
          setErrorMessageState(err.response.data.error);
        } else {
          setErrorMessageState("Ticket couldn't be generated. Please try again");
        }
      });
    return () => {};
  }, [ticketInput, location, setUser]);

  const [data, setData] = useState({});
  const [ticketLoaded, setTicketLoaded] = useState(false);
  const [errorMessageState, setErrorMessageState] = useState(null);
  const [techFromParams, setTechFromParams] = useState(null);
  if (!ticketLoaded) {
    if (errorMessageState) {
      return <div className="articleDesc">{errorMessageState}</div>;
    }
    return <div className="articleDesc">Loading ticket...</div>;
  }
  const formBgImg = () => {
    if (currentImg) {
      return <img src={currentImg} alt="Ticket bg" />;
    } else if (techFromParams) {
      return <img src={techFromParams.bgImage} alt={techFromParams.title} />;
    } else {
      return (
        <img
          src="https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/hasura-bg.png"
          alt="Hasura background"
        />
      );
    }
  };
  const formTechIcon = () => {
    if (currentTechImg) {
      return <img src={currentTechImg} alt="Tech Icon" />;
    } else if (techFromParams) {
      return <img src={techFromParams.techImg} alt={techFromParams.title} />;
    } else {
      return (
        <img
          src="https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/hasura.svg"
          alt="Hasura Icon"
        />
      );
    }
  };
  return (
    <div className="ticketWrapper">
      <div className="ticketBgImage">
        {formBgImg()}
        <div className="ticketPosAbs">
          <div className="wd80">
            <div className="techIcon">{formTechIcon()}</div>
            <div className="articleSubTitle">
              {data.firstName} {data.lastName}
            </div>
            <div className="ticketFooter">
              <div className="brand">
                <img src={hasuraConBrand} alt="HasuraCon Brand" />
              </div>
              <div>
                <div className="articleDescSmall">June 23rd - 24th | Online</div>
                <div className="articleDesc">{data.hashedTicket}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ticket;
