export const techState = [
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/postgres.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/postgres.svg",
    title: "postgres",
    hashTags: "PostgreSQL",
    isSelect: false,
    bgImage:
      "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/postgres-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/mysql.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/mysql.svg",
    title: "mysql",
    hashTags: "MySQL",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/mysql-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/mongo.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/mongo.svg",
    title: "mongodb",
    hashTags: "MongoDB",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/mongodb-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/react.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/react.svg",
    title: "react",
    hashTags: "ReactJS,javascript",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/react-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/next.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/next.svg",
    title: "nextjs",
    hashTags: "NextJS,javascript",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/nextjs-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/typescript.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/typescript.svg",
    title: "typescript",
    hashTags: "TypeScript,javascript",
    isSelect: false,
    bgImage:
      "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/typescript-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/vue.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/vue.svg",
    title: "vue",
    hashTags: "VueJS,javascript",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/vue-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/angular.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/angular.svg",
    title: "angular",
    hashTags: "AngularJS,javascript",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/angular-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/haskell.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/haskell.svg",
    title: "haskell",
    hashTags: "Haskell",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/haskell-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/go.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/go.svg",
    title: "go",
    hashTags: "Golang",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/go-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/kotlin.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/kotlin.svg",
    title: "kotlin",
    hashTags: "Kotlin,Androiddev",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/kotlin-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/swift.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/swift.svg",
    title: "swift",
    hashTags: "Swift,iOSDev",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/swift-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/flutter.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/flutter.svg",
    title: "flutter",
    hashTags: "Flutter,Flutterdev",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/flutter-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/c.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/c.svg",
    title: "csharp",
    hashTags: "Csharp",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/c-bg.png",
  },
  {
    techImg: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/dotnet.svg",
    imgSrc: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/dotnet.svg",
    title: "dotnet",
    hashTags: "Dotnet",
    isSelect: false,
    bgImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/hasura-con-2021/dotnet-bg.png",
  },
];
